<template>
	<div class="px-0">
		<b-card no-body header-tag="header" footer-tag="footer">
			<template #header>
				<div class="d-flex flex-row">
					<div class="mr-2 text-success task-icon-card-header">
						<i class="fa fa-exchange" aria-hidden="true"></i>
					</div>
					<div class="text-muted task-label-card-header">
						<h6 class="mb-0 font-weight-bold">{{ FormMSG(26, 'Comments') }}</h6>
					</div>
				</div>
			</template>
			<VuePerfectScrollbar ref="ps-planning-comment" :style="`height: ${contentHeight}px`" :settings="settings">
				<b-card-body>
					<task-comment :key-value="{ xid: keyValue.xid, imageParentId: keyValue.imageParentId }" :new-comment="newComment" />
				</b-card-body>
			</VuePerfectScrollbar>
			<template #footer>
				<task-editor :task-xid="keyValue.xid" :reply-to="0" :image-parent-id="keyValue.imageParentId" @task-comment:added="commentAdded" />
			</template>
		</b-card>
	</div>
</template>

<script>
import TaskComment from '@/components/Task/TaskComment';
import TaskEditor from '@/components/Task/TaskEditor';
import languageMessages from '@/mixins/languageMessages';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
	name: 'Comments',

	components: {
		TaskComment,
		TaskEditor,
		VuePerfectScrollbar
	},

	mixins: [languageMessages],

	props: {
		imageParentType: {
			type: String,
			required: false,
			default: 'comment'
		},
		imageParentSubType: {
			type: String,
			required: false,
			default: 'images'
		},
		keyValue: {
			type: Object,
			required: true,
			default: () => ({
				xid: null,
				imageParentId: 0
			})
		},
		contentHeight: {
			type: [String, Number],
			required: false,
			default: '500'
		}
	},
	data() {
		return {
			newComment: null,
			settings: {
				maxScrollbarLength: 60
			}
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs['ps-planning-comment'].update();
			setTimeout(() => {
				this.autoScrollToEnd('ps-planning-comment');
			}, 1000);
		});
	},
	methods: {
		commentAdded(payload) {
			this.newComment = payload;
			this.$refs['ps-planning-comment'].update();
			setTimeout(() => {
				this.autoScrollToEnd('ps-planning-comment');
			}, 200);
		}
	}
};
</script>

<style>
.scroll-comments {
	position: relative;
	height: 500px;
}
</style>
